@import "./src/sass/app";

.container {
	min-height: 100vh;
	width: 100%;
	background: $color-indigo url('/images/logo-home-secure.svg') no-repeat top 30px left 30px;
	background-size: 120px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: $color-white;
	position: relative;
}

.innerContainer {
	max-width: 750px;
	padding: 50px 0;
}

.containerTakeover {
	min-height: 100vh;
	width: 100%;
	background: $color-white url('/images/logo-home-secure-inverse-1.svg') no-repeat top 30px left 30px;
	background-size: 100px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: $color-indigo;
	position: relative;
}

.innerContainerTakeover {
	//max-width: 1440px;
	max-width: 90%;
	padding: 100px 0;
	width: 100%;
}

@include medium() {
	.containerTakeover {
		background-size: 80px;
	}
}

@include mobile() {
	.container {
		background-size: 80px;
		background-position: top 15px left 5px;
	}

	.containerTakeover {
		background-position: center top 40px;
		background-size: 110px;
		padding-top: 180px;
	}

	.innerContainer {
		max-width: none;
		padding: 50px 20px 70px;
	}

	.innerContainerTakeover {
		max-width: none;
		padding: 0;
	}
}