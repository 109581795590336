@mixin mobile() {
	@media (max-width: 992px) {
		@content;
	}
}

@mixin medium() {
	@media (min-width: 768px) and (max-width: 1200px) {
		@content;
	}
}