@import "./src/sass/app";

.footer {
	position: absolute;
	bottom: 40px;
	right: 110px;
	font-size: 12px;
}

.footer a {
	color: $color-spearmint;
}

.takeover a {
	color: $color-indigo;
}

@include mobile() {
	.footer {
		width: 100%;
		text-align: center;
		bottom: 20px;
		right: 0;
	}
}