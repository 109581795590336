@font-face {
    font-family: 'engrezbold';
    src: url('./fonts/engrez-bold-webfont.woff2') format('woff2'),
    url('./fonts/engrez-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'engrezmedium';
    src: url('./fonts/engrez-medium-webfont.woff2') format('woff2'),
    url('./fonts/engrez-medium-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'engrezregular';
    src: url('./fonts/engrez-webfont.woff2') format('woff2'),
    url('./fonts/engrez-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

body {
    margin: 0;
    font-family: 'engrezregular', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 16px;
    line-height: 20px;
    font-synthesis: none;
}

h1, h2, h3 {
    font-family: 'engrezbold', sans-serif;
}

h1 {
    font-size: 36px;
}

.bold {
    font-family: 'engrezbold', 'sans-serif'
}

.semi-bold {
    font-family: 'engrezmedium', 'sans-serif';
}

a {
    color: #C1EECD;
}

a:hover {
    text-decoration: underline;
    color: #C1EECD;
}

.color-spearmint {
    color: #C1EECD;
}

.color-indigo {
    color: #004346;
}

.color-purple {
    color: #BB8AFB;
}

.font-tiny {
    font-size: 12px;
}

.font-small {
    font-size: 14px;
    line-height: 20px;
}

.font-medium {
    font-size: 18px;
    line-height: 26px;
}

.font-large {
    font-size: 20px;
    line-height: 28px;
}

.font-larger {
    font-size: 22px;
    line-height: 26px;
}

.font-xlarge {
    font-size: 24px;
    line-height: 28px;
}

.row-error {
    font-size: 14px;
    color: #a94442;
    background: #f2dede;
    border: 1px solid #ebccd1;
    border-radius: 5px;
    padding: 15px;
}

.z-index-2 {
    z-index: 2;
}

@media (max-width: 992px) {
    body {
        font-size: 15px;
        line-height: 19px;
    }
}